<template>
  <b-card>
      <b-form @submit.prevent>
        <b-row>
             <!-- hesap sahibi adı -->
           <b-col cols="12">
              <b-form-group
                :label="$t('Account Owner Name')"
                label-for="account_owner_name"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ItalicIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="account_owner_name"
                    name="account_owner_name"
                    type="text"
                    :placeholder="$t('Account Owner Name')"
                    v-model="seller_data.AccountOwnerName"
                    @blur="$v.seller_data.AccountOwnerName.$touch()"
                  />

                </b-input-group>

                <small v-if="isSubmit &&  !$v.seller_data.AccountOwnerName.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>
          </b-col>

                <!-- hesap sahibi adı-->
           <b-col cols="12">
              <b-form-group
                :label="$t('Account Owner Last Name')"
                label-for="account_owner_last_name"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ItalicIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="account_owner_last_name"
                    name="account_owner_last_name"
                    type="text"
                    :placeholder="$t('Account Owner Last Name')"
                    v-model="seller_data.AccountOwnerLastName"
                    @blur="$v.seller_data.AccountOwnerLastName.$touch()"
                  />

                </b-input-group>
                <small v-if="isSubmit &&  !$v.seller_data.AccountOwnerLastName.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>
          </b-col>
          <!-- Telefon Numarası-->
        <b-col cols="12">
                <b-form-group label-for="scheduler" :label="$t('Company Type')">
                    <b-form-radio
                    v-model="seller_data.CompanyType"
                    name="company_type"
                    value="true"
                    class="d-inline custom-radio"
                    >
                    {{$t('Anonymous/Ltd')}}
                    </b-form-radio>
                    <b-form-radio
                    v-model="seller_data.CompanyType"
                    name="company_type"
                    value="false"
                    class="d-inline mx-2 custom-radio"
                    >
                    {{$t('Sole Proprietorship')}}
                    </b-form-radio>
                  <small v-if="isSubmit &&  !$v.seller_data.CompanyType.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                </b-form-group>
          </b-col>


            <b-col cols="12" v-if="seller_data.CompanyType == 'false' || seller_data.CompanyType == false">
            <b-form-group :label="$t('Identification Number')" label-for="identity_no">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="identity_no"
                  :placeholder="$t('Identification Number')"
                  type="number"
                  maxlength="11"
                  minlength="11"
                  v-model="seller_data.Tckn"
                  @blur="$v.seller_data.Tckn.$touch()"
                />
              </b-input-group>
                 <small v-if="isSubmit &&  !$v.seller_data.Tckn.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                 <small v-if="!$v.seller_data.Tckn.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                 <small v-if="!$v.seller_data.Tckn.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 11 characters")}}</small>
                 <small v-if="!$v.seller_data.Tckn.minLength" class="form-text text-danger" >{{$t("Can contain a minimum of 11 characters")}}</small>
            </b-form-group>
          </b-col>

            <b-col cols="12" v-if="seller_data.CompanyType == 'true' || seller_data.CompanyType == true">
            <b-form-group
              :label="$t('Task Code')"
              label-for="task_code"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BookOpenIcon" />
                </b-input-group-prepend>
                <b-form-input
                name="task_code"
                  id="task_code"
                  :placeholder="$t('Tax Code')"
                  type="number"
                  maxlength="10"
                  minlength="10"
                  v-model="seller_data.Vkn"
                  @blur="$v.seller_data.Vkn.$touch()"
                />
              </b-input-group>
                <small v-if="isSubmit &&  !$v.seller_data.Vkn.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                <small v-if="!$v.seller_data.Vkn.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                <small v-if="!$v.seller_data.Vkn.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 10 characters")}}</small>
                <small v-if="!$v.seller_data.Vkn.minLength" class="form-text text-danger" >{{$t("Can contain a minimum of 10 characters")}}</small>
            </b-form-group>
          </b-col>


          <!-- Iban No -->
          <b-col cols="12">
            <b-form-group
              :label="$t('IBan')"
              label-for="iban"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="iban"
                  name="iban"
                  :placeholder="$t('IBan')"
                  type="text"
                  maxlength="34"
                  minlength="26"
                  v-model="seller_data.Iban"
                  @blur="$v.seller_data.Iban.$touch()"/>
              </b-input-group>
                  <small v-if="isSubmit && !$v.seller_data.Iban.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                  <!-- <small v-if="!$v.seller_data.Iban.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small> -->
                  <small v-if="!$v.seller_data.Iban.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 26 characters")}}</small>
                  <small v-if="!$v.seller_data.Iban.minLength" class="form-text text-danger" >{{$t("Can contain a minumum of 24 characters")}}</small>
            </b-form-group>
          </b-col>

      <b-col cols="12">
            <b-form-group :label="$t('Commission Value')" label-for="commission_value">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlignCenterIcon" />
                </b-input-group-prepend>
                <input
                  class="form-control"
                  id="commission_value"
                  type="number"
                  maxlength="20"
                  v-model="seller_data.CommissionValue"
                  @blur="$v.seller_data.CommissionValue.$touch()"
                />
              </b-input-group>
               <small v-if="isSubmit && !$v.seller_data.CommissionValue.minValue" class="form-text text-danger" >{{$t("Komisyon tutarı 0 dan küçük olamaz")}}</small>
            </b-form-group>
        </b-col>


          <!-- reset and submit -->
    <b-col cols="12" class="right">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        type="reset"
        variant="outline-primary"
        class="mr-1"
        :to="{ name: 'studio_list' }"
      >
        {{ $t("Cancel") }}
      </b-button>

      <b-button class="ml-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="paymentAddSeller">
        {{ $t("Save") }}
      </b-button>
    </b-col>
  </b-row>
      </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BCardText,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadio
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";

import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required, numeric, maxLength, email, minValue} from "vuelidate/lib/validators";
import { ThumbsUpIcon } from 'vue-feather-icons';
import minLength from 'vuelidate/lib/validators/minLength';

export default {
  directives: {
    Ripple,
  },
  components: {
    ToastificationContent,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    Cleave,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormRadio
  },
  props: {
  },
  data() {
    return {
        seller_data:{
        SellerId:null,
        CompanyType :true,
        SellerCode :null,
        Tckn:null,
        Vkn:null,
        Iban :null,
        AccountOwnerName:null,
        AccountOwnerLastName :null,
        CommissionValue:4
       },
      dataloaded:false,
      block: {
        blocks: [500],
        uppercase: false,
      },
      userData: {},
      isPerson: false,
       isSubmit: false,
    };
  },
  validations:
    {
      seller_data:{
          CompanyType:{
            required:required,

        },
            Tckn:{
            required:required,
            numeric:numeric,
            maxLength:maxLength(11),
            minLength:minLength(11)
        },
            Vkn:{
            required:required,
            numeric:numeric,
            maxLength:maxLength(10),
            minLength:minLength(10)
        },

          Iban:{
            required:required,
            maxLength: maxLength(34),
            minLength: minLength(26)
        },
          AccountOwnerName:{
          required:required
        },
          AccountOwnerLastName:{
          required:required
        },
         CommissionValue:{
          required:required,
          minValue:3
        }
      }
    },

  methods: {
    ...mapActions("mockUser", ["fetchUser"]),

    paymentAddSeller()
    {
          if(this.seller_data.CompanyType == true)
          {
              this.seller_data.Tckn="11111111111"
              this.seller_data.SellerCode=this.seller_data.Vkn
          }
          else
          {
            this.seller_data.Vkn="1111111111"
            this.seller_data.SellerCode=this.seller_data.Tckn
          }

      this.isSubmit = true;
       if (this.$v.$invalid)
          return;

            this.$database.DistributorService.payment_add_seller(
            this.seller_data.SellerId,
            this.seller_data.CompanyType,
            this.seller_data.SellerCode,
            this.seller_data.Iban,
            this.seller_data.AccountOwnerName,
            this.seller_data.AccountOwnerLastName,
            this.seller_data.CommissionValue
      ).then((res) => {


        if (!res.IsSuccess) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title:this.$t('Action failed!')+" "+res.Result,
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Paratika için Tesis tanımladı"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push('/studioList')
        }
      });
    },

      getPaymentSeller(sellerId)
     {
       this.$database.DistributorService.payment_get_seller(sellerId).then(
         res => {
            if (res.IsSuccess) 
            {
                this.seller_data.SellerId = sellerId
                //this.seller_data.CompanyType ="";
                //this.seller_data.SellerCode="";
                this.seller_data.Iban=res.Result.Iban;
                this.seller_data.AccountOwnerName= res.Result.AccountOwnerName;
                this.seller_data.AccountOwnerLastName= res.Result.LastName;
                // this.seller_data.CommissionValue="";

              if ( res.Result.Tckn != "")
              {
                  this.seller_data.CompanyType=false
                  this.seller_data.Tckn=res.Result.Tckn;
              }
              if(res.Result.Vkn != "")
              {
                this.seller_data.CompanyType = true;
                this.seller_data.Vkn= res.Result.Vkn;
              }
                console.log(res.Result);
            }  
         }
      ); 
    }





  },
  watch: {
    'seller_data.CompanyType'(value){
       this.seller_data.Vkn=null;
       this.seller_data.Tckn=null;
    },
  },
  created() {
  this.seller_data.SellerId=this.$route.params.studioID;

    this.getPaymentSeller(this.seller_data.SellerId)
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioPaymentAddScoped.scss" scoped></style>
