import { render, staticRenderFns } from "./studio_payment_add.vue?vue&type=template&id=24c34648&scoped=true&"
import script from "./studio_payment_add.vue?vue&type=script&lang=js&"
export * from "./studio_payment_add.vue?vue&type=script&lang=js&"
import style0 from "@/styles/scss/studioPaymentAddScoped.scss?vue&type=style&index=0&id=24c34648&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c34648",
  null
  
)

export default component.exports